import React, { useState, useEffect } from 'react';
import './App.css';
import me from './1690279597245.jpeg';
import memoContact from './memoContact.png';
import logo from './logo-transparent-png3.png';
import mockup from './smartmockups_llv9j5xvNEW.png'

import ahorra from './logoahorra.png'
import unmatindemai from './logounmatindemai.png'
import boucheriekielwasser from './logokielwasser.png'
import logokm0 from './logokm0.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobe, faMobileAlt, faGears, faMobilePhone } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const cards = [
  { text: 'sites web', icon: faGlobe, hoverText: 'Offrez vous une visibilité supplémentaire à l\'aide d\'un site internet.<br/><br/>Présenter votre commerce ou encore vendre en ligne, tout devient possible.' },
  { text: 'applications', icon: faMobileAlt, hoverText: 'Vous avez une idée d\'applicaion ?<br/><br/>Je serai ravi de vous accompagner dans son développement.' },
  { text: 'logiciels', icon: faGears, hoverText: 'Je vous propose le développement d\'un logiciel afin de contribuer au bon fonctionnement de votre structure.' },
];

const projects = [
  { imageUrl: ahorra },
  { imageUrl: unmatindemai },
  { imageUrl: boucheriekielwasser },
  { imageUrl: logokm0 },
];

function App() {
  const handlePhoneClick = () => {
    window.location.href = 'tel:+33651322365';
  };
  const handleEmailClick = () => {
    window.location.href = 'mailto:contact@oscar-dev.fr';
  };
  const handleLinkedInClick = () => {
    window.open('https://www.linkedin.com/in/oscar-frank/', '_blank');
  };

  useEffect(() => {
    const smoothScrollLinks = document.querySelectorAll('a[href^="#"]');
    smoothScrollLinks.forEach(link => {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        const targetId = this.getAttribute('href');
        const targetElement = document.querySelector(targetId);
        if (targetElement) {
          let vhInPixels = window.innerHeight * 0.07;
          window.scrollTo({
              behavior: 'smooth',
              top: targetElement.offsetTop - vhInPixels
          });
        }
      });
    });
  }, []);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
  let timeout;
  if (showPopup) {
      timeout = setTimeout(() => {
      setShowPopup(false);
      }, 4500);
  }

  return () => {
      clearTimeout(timeout);
  };
  }, [showPopup]);

  const handleMentionsLegalesClick = () => {
      setShowPopup(true);
  };

  return (
    <div className="App">
      <div className='topbar'>
        <a href="/">
          <img className='logo-topbar' src={logo} alt='logo' />
        </a>
      </div>
      <div className='Home'>
        <div className='left-home'>
          <div className='left-container'>
            <span className='title'>
              Un <span style={{color:'#008DC4'}}>développement</span> sur mesure
            </span>
            <p className='subtitle'>
              <i>OSCAR-DEV</i> est une agence de développement dédiée à la création de solutions numériques innovantes.
            </p>
            <a href="#discover-me" className="button-discover-our-solutions">Me découvrir</a>
          </div>
        </div>
        <div className='right-home'>
          <div className='right-container'>
            <img alt='mockup' src={mockup} style={{maxWidth:'650px', width:'90%'}} />
          </div>
        </div>
      </div>

      <div className='aboutUs' id='discover-me' style={{borderTop:'1px solid #666', backgroundColor:'aliceblue'}}>
        <div className='aboutUs-container-title' style={{margin:'7vh'}}>
          <p className='title-about'>
            Me découvrir
          </p>
        </div>
        <div className='all-about-container'>
          <div className='left-about'>
            <div className='left-container-about' style={{backgroundColor:'aliceblue'}}>
              <img src={me} className='meAsImg2' alt='me' />
              <p className='subtitle-about'>Oscar FRANK</p>
            </div>
          </div>
          <div className='right-about'>
            <div className='right-container-about'>
              <p className='subtitle-about'>
                Étudiant en 4ème année du Programme Grande École à EPITECH Mulhouse, passionné de développement informatique mais aussi d'entrepreneuriat, j'ai décidé de créer <i>OSCAR-DEV</i> afin de vous accompagner dans vos projets.
                <br/>
                <br/>
                Créée en 2023, <i>OSCAR-DEV</i> est une agence de développement qui propose des solutions numériques innovantes, comme des sites vitrines, des boutiques e-commerce, des applications mobiles ou encore des logiciels professionnels.
              </p>
              <br/>
              <p className='subtitle-about'>
                <i>
                  Vous pouvez, ci-dessous, découvrir les différentes solutions que je propose.
                </i>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='Our-solutions' id='our-solutions'>
        <div className='intro-solutions'>
          <span className='solutions-title'>Mes services</span>
          <div className="produits">
            <div className="productWrapper">
              {cards.map((card, index) => (
                <div className="product" key={index}>
                  <div className="card-content">
                    <div className="text-container">
                      <FontAwesomeIcon icon={card.icon} style={{ fontSize: '30px', color: 'aliceblue' }} />
                      <p className="productText" style={{ color: 'aliceblue', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: card.text }}></p>
                      <div className='moreAbout-button'>En savoir plus</div>
                    </div>
                    <div className="overlay"></div>
                      <div className="center-text">
                        <p className="hoverText" style={{color:'aliceblue'}} dangerouslySetInnerHTML={{ __html: card.hoverText }}></p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      <div className='they-trust-us-container'>
        <div className='they-trust-us'>
          <span className='they-trust-us-title'>Ils me font confiance</span>
          <div className="produits2">
            <div className="productWrapper2">
              {projects.map((card, index) => (
                <div className="productLink2" key={index}>
                  <div className="product2" style={{ backgroundImage: `url(${card.imageUrl})` }}>
                    <div className="overlay2"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='contact-me-container'>
        <div className='aboutUs'>
          <div className='aboutUs-container-title'>
            <p className='title-about'>
              Me contacter
            </p>
          </div>
          <div className='all-about-container'>
            <div className='right-about'>
              <div className='right-container-about'>
                <p className='subtitle-about'>
                  En me contactant, bénéficiez d'un accompagnement personnalisé dans le développement de vos projets.
                  <br/>
                  <br/>
                  Je développe des environnements digitaux afin de vous accompagner au mieux dans votre stratégie.
                </p>
                <br/>
                <p className='subtitle-about'>
                  <i>
                    Pour toute demande, n'hésitez pas à me contacter.
                  </i>
                </p>
              </div>
            </div>
            <div className='left-about'>
              <div className='left-container-about'>
                <img src={memoContact} className='meAsImg' alt='me' />
                <p className='subtitle-about'>Oscar FRANK</p>
              </div>
            </div>
          </div>
          <div className='buttons-contact-container'>
            <div className='new-button-contact' onClick={handlePhoneClick}>
              <FontAwesomeIcon icon={faMobilePhone} style={{ fontSize: 'calc(0.7rem + 1vmin)', color: 'aliceblue', marginRight:'15px' }} />
              <span className="info-button">06 51 32 23 65</span>
            </div>
            <div className='new-button-contact' onClick={handleEmailClick}>
              <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: 'calc(0.7rem + 1vmin)', color: 'aliceblue', marginRight:'15px' }} />
              <span className="info-button">contact@oscar-dev.fr</span>
            </div>
            <div className='new-button-contact' onClick={handleLinkedInClick}>
              <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: 'calc(0.7rem + 1vmin)', color: 'aliceblue', marginRight:'15px' }} />
              <span className="info-button">Oscar FRANK</span>
            </div>
          </div>
        </div>
      </div>
        <div className="footer">
            <span className="footerText">
                © 2023 oscar-dev |{' '}
                <span className="mentionsLegales" onClick={handleMentionsLegalesClick}>
                mentions légales
                </span>
            </span>
            {showPopup && (
                <div className="popup">
                    <p style={{fontWeight: 'bold'}}>Mentions légales</p>
                    <p>Oscar FRANK | Entrepreneur individuel</p>
                    <p>SIRET: 95360945000010</p>
                    <p>Siège social: 14 rue de Bourgogne, 68100 Mulhouse</p>
                    <p>Mail: contact@oscar-dev.fr</p>
                    <p>Téléphone: +33 6 51 32 23 65</p>
                    <p>Responsable de la publication: Oscar FRANK</p>
                    <p>Hébergeur: LWS | 2 Rue Jules Ferry, 88190 Golbey | +33 1 77 62 30 03</p>
                </div>
            )}
        </div>
    </div>
  );
}

export default App;
